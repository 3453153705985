import { storeToRefs } from "pinia";
import { useGeneralStore } from "~/stores/general";
import { OrderData } from "@/types/api/orders.types";
import { Cart } from "~/types/api/cart.types";

export const useFacebookPixelEvents = () => {
  const { t } = useI18n();
  const generalStore = useGeneralStore();
  const {
    getIntegrationCallbackStatuses: integrationCallbackStatuses,
    settings,
  }: any = storeToRefs(generalStore);
  const isFacebookPixelAvailable = computed(() => {
    return (
      integrationCallbackStatuses.value.TYPE_FACEBOOK_PIXEL &&
      Boolean((window as any)?.fbq)
    );
  });
  function fbPriceFormat(price: any) {
    price = price || 0;
    return Number(price).toFixed(2);
  }
  function fbViewContentEvent(data: any, configurationData: any) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "ViewContent", {
      content_type: "product",
      content_ids: [configurationData?.configuration_id],
      content_name: data?.currentTranslation?.title || data?.title || "",
      content_category: data.collections?.[0]?.currentTranslation?.title || "",
      value: fbPriceFormat(configurationData?.cost || data.base_cost),
      currency: settings.value?.currency_code || "",
    });
  }
  function fbAddToCartEvent(data: any, configurationData: any) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "AddToCart", {
      content_type: "product",
      content_ids: [configurationData?.configuration_id],
      content_name: data?.currentTranslation?.title || data?.title || "",
      content_category:
        data.collections?.[0]?.currentTranslation?.title ||
        data.collections_ids?.[0] ||
        "",
      value: fbPriceFormat(configurationData?.cost || data.base_cost),
      currency: settings.value?.currency_code || "",
    });
  }
  function fbInitiateCheckoutEvent(data: Cart) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "InitiateCheckout", {
      content_type: "product",
      content_name: t("checkout"),
      value: fbPriceFormat(data.final_amount),
      currency: settings.value?.currency_code || "",
      num_items: data.items.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      ),
      content_ids: data.items?.map((item: any) => item.configuration_id),
    });
  }
  function fbPurchaseEvent(data: OrderData) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "Purchase", {
      content_type: "product",
      content_name: t("orderPlaced"),
      value: fbPriceFormat(data.final_amount),
      currency: data.currency_code,
      num_items: data.order_products.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      ),
      content_ids: data.order_products?.map(
        (item: any) => item.configuration_id
      ),
    });
  }
  return {
    integrationCallbackStatuses,
    fbViewContentEvent,
    fbAddToCartEvent,
    fbInitiateCheckoutEvent,
    fbPurchaseEvent,
  };
};
